<template>
  <div @click.stop="" class="modal">
    <span @click="toggleInfo" class="close" title="Fechar campo">
      <icone-fechar />
    </span>
          
    <div id="area_edicao">
      <h2>Edição</h2>

      <div >
        <div >
          <h3 class="sr-only">Autor</h3>
          <p>{{edicao.autor.nome}}</p>
        </div>

        <div >
          <h3 class="sr-only">Data</h3>
          <p>{{stringifyDate(edicao.data)}}</p>
        </div>
      </div>

      <div class="info"  v-if="diferencaDemandas('status')">
        <h3 class="sr-only">Status</h3>
        <div >
          <div >
            <h3 class="sr-only">Original</h3>
            <p>{{edicao.especificacao.demanda_original.status}}</p>
          </div>
          <div >
            <h3 class="sr-only">Editada</h3>
            <p>{{edicao.especificacao.demanda_alterada.status}}</p>
          </div>
        </div>
      </div>

      <div class="info"  v-if="diferencaDemandas('prazo')">
        <h3 class="sr-only">Prazo</h3>
        <div >
          <div >
            <h3 class="sr-only">Original</h3>
            <p>{{stringifyDate(edicao.especificacao.demanda_original.prazo)}}</p>
          </div>
          <div >
            <h3 class="sr-only">Editada</h3>
            <p>{{stringifyDate(edicao.especificacao.demanda_alterada.prazo)}}</p>
          </div>
        </div>
      </div>

      <div class="info"  v-if="diferencaDemandas('responsaveis')">
        <h3 class="sr-only">Responsaveis</h3>
        <div >
          <div >
            <h3 class="sr-only">Original</h3>
            <p>{{listaResponsaveisOriginal}}</p>
          </div>
          <div >
            <h3 class="sr-only">Editada</h3>
            <p>{{listaResponsaveisEditada}}</p>
          </div>
        </div>
      </div>

      <div class="info"  v-if="diferencaDemandas('titulo')">
        <h3 class="sr-only">Título</h3>
        <div >
          <div >
            <h3 class="sr-only">Original</h3>
            <p>{{edicao.especificacao.demanda_original.titulo}}</p>
          </div>
          <div >
            <h3 class="sr-only">Editada</h3>
            <p>{{edicao.especificacao.demanda_alterada.titulo}}</p>
          </div>
        </div>
      </div>

      <div class="info"  v-if="diferencaDemandas('descricao')">
        <h3 class="sr-only">Descrição</h3>
        <div >
          <div >
            <h3 class="sr-only">Original</h3>
            <p id="p_descricao_info_og"></p>
          </div>
          <div >
            <h3 class="sr-only">Editada</h3>
            <p id="p_descricao_info_ed"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pegar_DATA_HORA } from '@/utils/dates'
import IconeFechar from '@/assets/SVGs/iconeFechar.vue'

export default {
  components: {
    'icone-fechar': IconeFechar,
  },
  props: {
    edicao: {
      type: Object,
      required: true
    }
  },

  mounted() {
    if(this.diferencaDemandas('descricao')) {
      let element = document.querySelector('#p_descricao_info_og')
      element.innerHTML = this.urlify(this.edicao.especificacao.demanda_original.descricao)
      element = document.querySelector('#p_descricao_info_ed')
      element.innerHTML = this.urlify(this.edicao.especificacao.demanda_alterada.descricao)
    }
  },

  methods: {
    diferencaDemandas(parametro){
      const parametroOriginal = JSON.stringify(this.edicao.especificacao.demanda_original[parametro])
      const parametroEditado = JSON.stringify(this.edicao.especificacao.demanda_alterada[parametro])

      return parametroOriginal != parametroEditado
    },

    stringifyDate(data) {
      return pegar_DATA_HORA(data)
    },
    toggleInfo() {
      this.$emit('toggleInfoEdicao', { edicao: {} })
    },

    urlify(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return `<a href="${url}" target="_blank">${url.substring(0, 20)}...</a>`;
      })
    }
  },

  computed : {
    listaResponsaveisOriginal() {
      let responsas = this.edicao.especificacao.demanda_original.responsaveis.map(({nome}) => nome)
      responsas = responsas.sort((a, b) => {
        if(a.toUpperCase() > b.toUpperCase) return 1
        if(a.toUpperCase() < b.toUpperCase) return -1
        return 0
      })

      if(responsas.length == 1) return responsas[0]
      if(responsas.length == 2) return responsas.join(' e ')

      const ultimo = responsas.pop()

      return `${responsas.join(', ')} e ${ultimo}`
    },
    listaResponsaveisEditada() {
      let responsas = this.edicao.especificacao.demanda_alterada.responsaveis.map(({nome}) => nome)
      responsas = responsas.sort((a, b) => {
        if(a.toUpperCase() > b.toUpperCase) return 1
        if(a.toUpperCase() < b.toUpperCase) return -1
        return 0
      })

      if(responsas.length == 1) return responsas[0]
      if(responsas.length == 2) return responsas.join(' e ')

      const ultimo = responsas.pop()

      return `${responsas.join(', ')} e ${ultimo}`
    },

    descricaoOriginalURL() {
      const element = document.querySelector('#p_descricao_info_og')
      element.innerHTML = this.urlify(this.edicao.especificacao.demanda_original.descricao)
    },
    descricaoEditadaURL() {
      const element = document.querySelector('#p_descricao_info_ed')
      element.innerHTML = this.urlify(this.edicao.especificacao.demanda_alterada.descricao)
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'Edicao.scss';
@import '../Info.scss';
</style>