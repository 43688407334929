<template>
  <div id="area_tabela">
    <table>
      <thead>
        <tr>
          <th></th>
          <th id="th_titulo">Autor</th>
          <th id="th_url">Data</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(edicao, index) in edicoes_locais" 
          :key="edicao._id"
          :style="{ 
            opacity: edicaoSelecionada._id == edicao._id ? 1 : '',
            'font-weight': edicaoSelecionada._id == edicao._id ? 600 : '' 
          }"
        >
          <td class="acao">
            <button type="button" class="svg_acao clear_button fa-search" 
              title="Ver detalhes da edição" 
              @click="toggleInfo(index)"
            >
              <icone-lupa />
            </button>
          </td>

          <td>{{edicao.autor.nome}}</td>
          <td>{{stringifyDate(edicao.data)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { pegar_DATA_HORA } from '@/utils/dates'
import toggleModals from '@/mixins/toggleModals'

import IconeLupa from '@/assets/SVGs/iconeLupa.vue'

export default {
  components: {
    'icone-lupa': IconeLupa,
  },

  props: {
    edicoes: {
      type: Array,
      required: true
    },
    edicaoSelecionada: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      edicoes_locais: [],

			modalStates: {
				infoEdicao: false,
			},
    }
  },

  mixins: [toggleModals],
  mounted() {
    const edicoes_ordenadas = this.edicoes.sort((a, b) => {
      const date_a = new Date(a.data).getTime()
      const date_b = new Date(b.data).getTime()

      if(date_a > date_b) return -1
      if(date_a > date_b) return 1
      return 0
    })
    this.edicoes_locais = edicoes_ordenadas
  },

  methods: {
    stringifyDate(data) {
      return pegar_DATA_HORA(data)
    },

    toggleInfo(index) {
      this.$emit('toggleInfoEdicao', { edicao: this.edicoes_locais[index] })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../Tables.scss';
@import 'InfoEdicoes.scss';
</style>