<template>
  <div class="header_pagina">
    <h1>Demanda</h1>
  </div>

  <section v-if="!loaded">
    <p>Carregando...</p>
  </section>
  <section 
    v-else
  >
    <div id='section_info_solicitacao'>
      <div class="header_solicitacao">
        <div id="area_titulo">
          <h2 id="titulo_soli" style="white-space: initial;"> {{demanda.titulo}} </h2>
        </div>

        <div v-if="userRelacionado">
          <my-button icone="edit" texto="Editar demanda" 
              @buttonClick="toggleModalLocal('editDemanda')"
          />
          <transition name="fade">
            <modal-edit
              v-if="modalStates.editDemanda" 
              :demanda="demanda"
              @toggleEditDemanda="toggleModal" 
              @edittedDemanda="updateDemanda"
            />
          </transition>
        </div>
      </div>

      <!-- <div class="linha"></div> -->
      <hr class="linha">

      <div class="area_info id">
        <h3 class="info_title">ID:</h3>
        <p class="info_data" id="add_id_solicitacao">{{demanda._id}}</p>
      </div>
      <div class="area_solicitacao_infos">
        <div class="info_section">
          <div class="area_info">
            <h3 class="info_title">Autor:</h3>
            <p class="info_data">{{demanda.autor.nome}}</p>
          </div>
          
          <div class="area_info">
            <h3 class="info_title">Status:</h3>
            <p class="info_data" id="status_soli">{{demanda.status}}</p>
          </div>
        </div>
        
        <div class="info_section">
          <div class="area_info">
            <h3 class="info_title">Setores:</h3>
            <p class="info_data">
              {{setoresResponsaveis}}
            </p>
          </div>
          
          <div class="area_info">
            <h3 class="info_title">Responsáveis:</h3>
            <p class="info_data">
              {{nomesResponsaveis}}
            </p>
          </div>
        </div>

        <div class="info_section">
          <div class="area_info">
            <h3 class="info_title">Abertura:</h3>
            <p class="info_data">{{stringifyDate(demanda.data)}}</p>
          </div>
          <div class="area_info">
            <h3 class="info_title">Prazo:</h3>
            <p class="info_data">{{stringifyDate(demanda.prazo)}}</p>
          </div>
        </div>

        <div class="info_section">
          <div class="area_info descricao">
            <h3 class="info_title">Descrição:</h3>
            <p class="info_data" id="descricao_soli"></p>
          </div>
        </div>

        <div
          v-if="demanda.anexos"
        >
          <div
            v-for="(anexo, index) in demanda.anexos" :key="anexo"
          >
            <div class="info_section">
              <div class="area_info download_anexo form_download">
                <h4 class="info_title">Anexo {{index+1}}</h4>

                <a :href="linkAnexo(demanda._id, anexo.id_gerado)" target="_blank">
                  <my-button icone="download" :texto="anexo.filename"
                    style="margin-right: 1rem;"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="acoes" v-if="userRelacionado">
      <div v-if="demanda.status != 'Em andamento'">
        <my-button icone="check" texto="Mover para 'Em andamento'" 
          @buttonClick="mudarStatus('Em andamento')"
        />
      </div>
      <div v-if="demanda.status != 'Concluída'">
        <my-button icone="concluida" texto="Concluir demanda" 
          @buttonClick="mudarStatus('Concluída')"
        />
      </div>
    </div>

    <div>
      <div>
        <h2>Edições</h2>
      </div>
      <div>
        <div v-if="edicoes.length == 0">
          <p>Nenhuma edição!</p>
        </div>
        <div id="area_edicoes" v-else>
          <table-edicoes :edicoes="edicoes"
            @toggleInfoEdicao="passEdicao"
            :edicaoSelecionada="edicaoSelecionada"
          />
          
          <transition name="slide-fade">
            <info-edicao
              v-if="modalStates.infoEdicao"
              @toggleInfoEdicao="passEdicao"
              :edicao="edicaoSelecionada"
            />
          </transition>
          
        </div>
      </div>
    </div>
</section>
</template>

<script>
import EventService from "@/services/EventService";

import handleError from '@/mixins/handleError'
import toggleModals from '@/mixins/toggleModals'
import { pegar_DATA_HORA } from '@/utils/dates'

import ModalEdit from '@/components/Modals/Demandas/Edit.vue'
import TableEdicoes from '@/components/Tables/Demandas/InfoEdicoes.vue'
import InfoEdicao from '@/components/Info/Edicao/Edicao.vue'

import iconeConcluida from '@/assets/SVGs/iconeConcluida.vue'

import Button from '@/components/Buttons/Button.vue'

export default {
  components: {
    'modal-edit': ModalEdit,
    'my-button': Button,
    'icone-concluida': iconeConcluida,
    'table-edicoes': TableEdicoes,
    'info-edicao': InfoEdicao,
  },

  data() {
    return {
			loaded: false,
      demanda: {},
      edicoes: [],

			modalStates: {
				editDemanda: false,
				infoEdicao: false,
			},
			edicaoSelecionada: {},
    };
  },
  mixins: [handleError, toggleModals],

  async mounted() {
    try {
      const response = await EventService.fetchDemanda({ id: this.$route.params.id })
      this.demanda = response.demanda;
      this.edicoes = response.edicoes;
      this.loaded = true

      setTimeout(() => {
        let element = document.querySelector('#descricao_soli')
        element.innerHTML = this.urlify(this.demanda.descricao, 50)
      }, 100)
    } catch(e) {
      this.loaded = true
      console.log(e)
      if(e.message.indexOf('404') != -1) this.$router.push({ name: 'Demandas' })
      handleError(e)
    }
  },

  methods: {
    stringifyDate(data) {
      return pegar_DATA_HORA(data)
    },

    mudarStatus(novo_status) {
      this.$Swal.fire({
        title: `Alterar o status da demanda para ${novo_status}?`,
        text: 'Os responsáveis e autor serão notificados.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (!result.isConfirmed) return

        let pacote = {
          id: this.demanda._id,
          status: novo_status,
        }

        this.$store.dispatch('loading')

        EventService.editDemanda(pacote)
        .then(({ metrica_criada }) => {
          this.demanda.status = novo_status
          this.edicoes.unshift(metrica_criada)

          this.$store.dispatch('notLoading')
          
          this.$Swal.fire({
              title: "Demanda editada!",
              icon: "success"
          })
          this.toggleModal()
        })
        .catch(handleError)
      })
    },
    
    updateDemanda({ alteracoes, metrica_criada }) {
      if(!alteracoes) return

      Object.keys(alteracoes).forEach((parametro) => {
        this.demanda[parametro] = alteracoes[parametro]
      })

      this.edicoes.unshift(metrica_criada)
    },

    passEdicao({ edicao }) {
      if(!edicao._id || this.edicaoSelecionada._id == edicao._id) {
        this.edicaoSelecionada = {}
        return this.modalStates.infoEdicao = false
      }
      
      if(!this.edicaoSelecionada._id) {
        this.edicaoSelecionada = edicao
        return this.modalStates.infoEdicao = true
      }

      const diferentes = edicao._id != this.edicaoSelecionada._id

      if(diferentes){
        this.modalStates.infoEdicao = false
        setTimeout(() => {
          this.edicaoSelecionada = edicao
          this.modalStates.infoEdicao = true
        }, 300)
      }
    },

    linkAnexo(id_demanda, id_anexo) {
      const { getToken } = require('../../../services/auth')

      const url = process.env.NODE_ENV == 'local' ? 
        'http://localhost:3600/demandas/anexo' : 
        'https://api-central.thestartlaw.app/demandas/anexo'

      return `${url}?demanda_id=${id_demanda}&file_id=${id_anexo}&token=${getToken()}`
    },

    urlify(text, size=20) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return `<a href="${url}" target="_blank">${url.substring(0, size)}...</a>`;
      })
    }
  },

  computed: {
    setoresResponsaveis() {
      const responsaveis = [...this.demanda.responsaveis]
      const setores = responsaveis.map(({setor}) => setor)

      const setores_unicos = Array.from(new Set([...setores]))
      let retornavel = this.demanda.responsaveis[0].setor

      if(setores_unicos.length == 2)
        retornavel += ` e ${setores_unicos[1]}`
      else if (setores_unicos.length > 2) {
        const ultimo = setores_unicos.pop()
        retornavel = `${setores_unicos.join(', ')} e ${ultimo}`
      }

      return retornavel
    },
    nomesResponsaveis() {
      const responsaveis = [...this.demanda.responsaveis]
      const nomes = responsaveis.map(({nome}) => nome)
      let retornavel = nomes[0]

      if(nomes.length == 2)
        retornavel += ` e ${nomes[1]}`
      else if (nomes.length > 2) {
        const ultimo = nomes.pop()
        retornavel = `${nomes.join(', ')} e ${ultimo}`
      }

      return retornavel
    },
    userRelacionado() {
      const id_user = this.$store.state.user.id

      const autor = id_user == this.demanda.autor._id

      const ids_responsaveis = this.demanda.responsaveis.map(({ _id }) => _id)
      const responsavel = ids_responsaveis.includes(id_user)

      return autor || responsavel
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'Demanda.scss';
</style>